import { defineMessages } from 'react-intl';

const otpMessages = defineMessages({
  header: {
    id: 'otp.header',
    defaultMessage: 'One-time email passcode',
    description: 'Main heading for Email OTP page.',
  },
  description: {
    id: 'otp.description',
    defaultMessage: 'Send a passcode to the email below.',
    description: 'Main description for Email OTP page.',
  },
  buttonLabel: {
    id: 'otp.buttonLabel',
    defaultMessage: 'Send passcode',
    description: 'Button label for OTP.',
  },
  headerSessionExpired: {
    id: 'otp.headerSessionExpired',
    defaultMessage: 'The current passcode has expired',
    description: 'Main heading for Email OTP page when session is expired.',
  },
  descriptionSessionExpired: {
    id: 'otp.descriptionSessionExpired',
    defaultMessage: 'We can send you a new one.',
    description: 'Main description for Email OTP page when session is expired.',
  },
  buttonLabelSessionExpired: {
    id: 'otp.buttonLabelSessionExpired',
    defaultMessage: 'Get a new passcode',
    description: 'Button label for OTP when session is expired.',
  },
  previousButtonLabel: {
    id: 'otp.previousButtonLabel',
    defaultMessage: 'Back',
    description: 'Button label to go to Main page.',
  },
  emailFormatError: {
    id: 'otp.emailFormatError',
    defaultMessage: 'Invalid email format',
    description: 'Error displayed when email format is invalid.',
  },
  emailOTPError: {
    id: 'otp.emailOTPError',
    defaultMessage: 'An error occurred, please try again later.',
    description: 'Unexpected error when tried to send OTP.',
  },
  emailInputName: {
    id: 'otp.emailInputName',
    defaultMessage: 'Email',
    description: 'Name for email form field.',
  },
});

export default otpMessages;
