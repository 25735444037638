import React, { SyntheticEvent, useState } from 'react';
import { useIntl } from 'react-intl';

import { useHistory } from 'react-router-dom';
import {
  FormSection,
  FormField,
  Input,
  Button,
  ColumnLayout,
  Spinner,
} from '@amzn/awsui-components-react/polaris';
import logger from '../utils/logger';
import {
  startSession,
  signUp,
  newUser,
  answerLocaleChallenge,
} from '../services/emailOTPAuth';
import Container from '../components/Container';
import HelpLink from '../components/HelpLink';
import usePageTitle from '../utils/usePageTitle';
import otpMessages from '../i18n/otp.messages';

import styles from './input.module.css';
import { getInitialLocaleSelection } from '../services/locale';

function EmailOTPSignIn() {
  usePageTitle('One-time email passcode');
  const { formatMessage } = useIntl();

  const history = useHistory();
  const hState = history.location.state;
  let headerText = formatMessage(otpMessages.header);
  let formText = formatMessage(otpMessages.description);
  let buttonLabel = formatMessage(otpMessages.buttonLabel);
  let emailText = '';
  if (hState && (hState as any).sessionExpired) {
    headerText = formatMessage(otpMessages.headerSessionExpired);
    formText = formatMessage(otpMessages.descriptionSessionExpired);
    buttonLabel = formatMessage(otpMessages.buttonLabelSessionExpired);
    emailText = (hState as any).userEmail;
  }
  const [emailValue, setEmailValue] = useState(emailText);
  const [emailEntered, setEmailEntered] = useState(emailText !== ''); // start true if redirected back with an email already entered
  const [emailFormatError, setEmailFormatError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (e: CustomEvent) => {
    if (isEmail(e.detail.value)) {
      setEmailEntered(true);
      setEmailFormatError('');
    } else {
      setEmailEntered(false);
    }
    setEmailValue(e.detail.value);
  };

  const isEmail = (input: string) => {
    const emailRegExp = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    return emailRegExp.test(input);
  };

  const handleSendOTPClick = async (e?: CustomEvent) => {
    e?.preventDefault();

    if (!isEmail(emailValue)) {
      setEmailFormatError(formatMessage(otpMessages.emailFormatError));
      return;
    }
    try {
      setLoading(true);
      const isNewUser = (await newUser(emailValue)) as boolean;
      if (isNewUser) {
        await signUp(emailValue);
      }
      const cognitoUser = await startSession(emailValue);
      await answerLocaleChallenge(getInitialLocaleSelection(), cognitoUser);
      setLoading(false);
      history.push({
        pathname: `/otp/challenge`,
        search: history.location.search,
        state: {
          cognitoUserName: cognitoUser.getUsername(),
          cognitoSession: (cognitoUser as any).Session,
          userEmail: emailValue,
        },
      });
    } catch (err) {
      setEmailFormatError(formatMessage(otpMessages.emailOTPError));
      setLoading(false);
      logger.error(err);
    }
  };

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    await handleSendOTPClick();
  };

  return (
    <Container>
      <div className={loading ? 'disabled-div' : undefined}>
        <form onSubmit={handleSubmit}>
          <FormSection
            header={<h1 className="form-section-h1">{headerText}</h1>}
          >
            {loading ? (
              <div className={'center-spinner'}>
                <Spinner size="large" />
              </div>
            ) : null}
            <ColumnLayout>
              <div data-awsui-column-layout-root="true">
                <div>{formText}</div>
                <FormField
                  label={formatMessage(otpMessages.emailInputName)}
                  errorText={emailFormatError}
                  stretch={true}
                >
                  <Input
                    name="email"
                    type="email"
                    data-testid="text-input"
                    autocomplete={true}
                    value={emailValue}
                    onInput={handleEmailChange}
                    disabled={loading}
                    ariaRequired={true}
                    className={styles.mobileInput}
                  ></Input>
                </FormField>
                <div>
                  <span className="awsui-util-f-r">
                    <Button
                      data-testid="back-button"
                      onClick={() => history.goBack()}
                      formAction="none"
                    >
                      {formatMessage(otpMessages.previousButtonLabel)}
                    </Button>
                    <Button
                      variant="primary"
                      data-testid="submit-button"
                      onClick={handleSendOTPClick}
                      disabled={!emailEntered || loading}
                      formAction="submit"
                    >
                      {buttonLabel}
                    </Button>
                  </span>
                </div>
              </div>
            </ColumnLayout>
            <HelpLink />
          </FormSection>
        </form>
      </div>
    </Container>
  );
}

export default EmailOTPSignIn;
